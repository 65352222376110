@import "@angular/material/theming";
@import "variables";

@include mat-core();

$theme: mat-light-theme($app-primary, $app-accent, $app-warn);
$dark-theme: mat-dark-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($theme);

::ng-deep .dark-theme {
    @include angular-material-theme($dark-theme);

    .ngx-charts {
        text {
            fill: mat-color(map-get($dark-theme, foreground), text) !important;
        }
    }
}
