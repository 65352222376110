@import '@angular/material/theming';
@import 'palette';

$app-primary: mat-palette($mat-deep-orange, 500);
$app-accent: mat-palette($mat-blue-gray, 600);
$app-warn: mat-palette($mat-red);

$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);
